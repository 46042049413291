import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Comments from '../../../pages/Comments/Comments';

// import Challenge from '../../../pages/auth/Challenge/Challenge';
// import ForgotPassword from '../../../pages/auth/ForgotPassword/ForgotPassword';
import SignIn from '../../../pages/auth/SignIn/SignIn';
// import SignOut from '../../../pages/auth/SignOut/SignOut';
// import NotFound from '../../../pages/corporate/NotFound/NotFound';
import Home from '../../../pages/Home/Home';
import Polls from '../../../pages/Polls/Polls';

export enum RoutePaths {
    SignIn = 'auth/sign-in',
    Comments = 'comments',
    Polls = 'polls',
}

const AppRouter = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={RoutePaths.Polls} element={<Polls />} />
                <Route path={RoutePaths.Comments} element={<Comments />} />
                <Route path={RoutePaths.SignIn} element={<SignIn />} />
                {/* <Route
                    path='auth/forgot-password'
                    element={<ForgotPassword />}
                /> */}
                {/* <Route path='auth/challenge' element={<Challenge />} /> */}
                {/* <Route path='auth/sign-out' element={<SignOut />} /> */}
                {/* <Route path='*' element={<NotFound />} /> */}
            </Routes>
            {props.children}
        </BrowserRouter>
    );
};

export default AppRouter;
