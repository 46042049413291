import { Box, Divider, Modal, Typography } from '@mui/material';
import { useContext } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';

import CommentAPI from '../../../../api/CommentAPI';
import FormButton from '../../../../components/forms/FormButton/FormButton';
import FormTextField from '../../../../components/forms/FormTextField/FormTextField';
import {
    SessionContext,
    SessionContextType,
} from '../../../../contexts/session';
import { Comment, CommentStatus } from '../../../../models/Comment';
import { validateFormValues } from '../../../../utils/forms/validation';

import './CommentModerationReject.scss';

interface CommentModerationProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    comment: Comment;
    comments: Comment[];
    setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
    setParentOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const validate = validateFormValues(
    yup.object().shape({
        moderationText: yup.string().required(),
    }),
);

const CommentModerationReject = (
    props: CommentModerationProps,
): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const closeHandler = () => {
        props.setOpen(false);
    };

    const onSubmitHandler = async (values: any) => {
        try {
            await CommentAPI.update(
                props.comment.id,
                {
                    status: CommentStatus.Rejected,
                    moderationText: values.moderationText,
                },
                session,
            );

            props.setComments([
                ...props.comments.filter(
                    (comm) => comm.id !== props.comment.id,
                ),
            ]);

            props.setParentOpen(false);
            props.setOpen(false);
        } catch (err: unknown) {}
    };

    return (
        <Modal
            className='moderate-reject-modal'
            open={props.open}
            onClose={closeHandler}
        >
            <Box>
                <Typography>{props.comment.text}</Typography>
                <Divider />
                <Form
                    onSubmit={onSubmitHandler}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        invalid,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FormTextField
                                name='moderationText'
                                label='Reason for rejecting... '
                                fullWidth
                                required
                                autoFocus
                                multiline
                                minRows={3}
                                maxRows={8}
                            />
                            <FormButton
                                disabled={submitting || pristine || invalid}
                                type='submit'
                            >
                                Reject
                            </FormButton>
                        </form>
                    )}
                />
            </Box>
        </Modal>
    );
};

export default CommentModerationReject;
