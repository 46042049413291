import axios, {
    AxiosInstance,
    AxiosResponse,
    RawAxiosRequestConfig,
    RawAxiosRequestHeaders,
} from 'axios';

import { Session } from '../contexts/session';
import { APIGetPaginatedParams } from './types';
import { Poll, PollStatus } from '../models/Poll';

// API page limit for pagination. Max number of elements to retrieve per request
export const PollAPIPageSize = 100;

enum PollAPIPaths {
    Polls = '/polls',
    MyPolls = '/mypolls',
}

interface PollAPIUpdateDataType {
    status?: PollStatus | undefined;
    moderationText?: string;
}

interface GetManyPollsReturnType {
    count: number;
    data: Poll[];
    lastKey?: {
        [key: string]: any;
    };
}

export interface GetManyPollsRequestType extends APIGetPaginatedParams {
    status?: PollStatus | undefined;
}

class PollAPI {
    private static getPollAPIAxiosObj = (
        session?: Session,
        path?: string,
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = path
            ? process.env.REACT_APP_POLL_API_BASEURL?.replace(
                  PollAPIPaths.Polls,
                  path,
              )
            : process.env.REACT_APP_POLL_API_BASEURL;

        return axios.create({
            baseURL,
            headers,
        });
    };

    public static getMany = async (
        params?: GetManyPollsRequestType,
        session?: Session,
    ): Promise<GetManyPollsReturnType> => {
        const pollAPI = PollAPI.getPollAPIAxiosObj(session);

        const limit = params?.scanLimit ?? PollAPIPageSize.toString();
        const config: RawAxiosRequestConfig<GetManyPollsRequestType> = {
            params: {
                scanLimit: limit.toString(),
            },
        };

        if (config.params && params?.status) {
            config.params.status = params?.status;
        }

        if (config.params && params?.lastKey) {
            config.params.lastKey = JSON.stringify(params?.lastKey);
        }

        const { status, data } = await pollAPI.get<
            GetManyPollsReturnType,
            AxiosResponse<GetManyPollsReturnType>,
            GetManyPollsRequestType
        >('', config);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving polls');
        }
    };

    public static update = async (
        commentId: string,
        dataIn: PollAPIUpdateDataType,
        session: Session,
    ): Promise<Poll> => {
        const commentAPI = PollAPI.getPollAPIAxiosObj(session);

        const { status, data } = await commentAPI.patch<
            Poll,
            AxiosResponse<Poll>,
            PollAPIUpdateDataType
        >(`/${commentId}`, dataIn);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error updating comment');
        }
    };
}

export default PollAPI;
