import {
    Box,
    Container,
    Divider,
    Modal,
    Stack,
    Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import * as yup from 'yup';

import { SessionContext, SessionContextType } from '../../../contexts/session';
import { Poll, PollStatus } from '../../../models/Poll';
import PollAPI from '../../../api/PollAPI';
import { validateFormValues } from '../../../utils/forms/validation';
import FormTextField from '../../../components/forms/FormTextField/FormTextField';
import FormButton from '../../../components/forms/FormButton/FormButton';

import './PollModeration.scss';

const validate = validateFormValues(
    yup.object().shape({
        moderationText: yup.string().required(),
    }),
);

interface PollModerationProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    poll: Poll;
    polls: Poll[];
    setPolls: React.Dispatch<React.SetStateAction<Poll[]>>;
}

const PollModeration = (props: PollModerationProps): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [rejectOpen, setRejectOpen] = useState<boolean>(false);

    const closeHandler = () => {
        props.setOpen(false);
        setRejectOpen(false);
    };

    const onApproveClickHandler: React.MouseEventHandler<
        HTMLButtonElement
    > = async () => {
        try {
            await PollAPI.update(
                props.poll.id,
                {
                    status: PollStatus.Approved,
                },
                session,
            );

            props.setPolls([
                ...props.polls.filter((comm) => comm.id !== props.poll.id),
            ]);

            props.setOpen(false);
        } catch (err: unknown) {}
    };

    const onRejectClickHandler: React.MouseEventHandler<
        HTMLButtonElement
    > = async () => {
        setRejectOpen(true);
    };

    const onSubmitRejectHandler = async (values: any) => {
        try {
            await PollAPI.update(
                props.poll.id,
                {
                    status: PollStatus.Rejected,
                    moderationText: values.moderationText,
                },
                session,
            );

            props.setPolls([
                ...props.polls.filter((comm) => comm.id !== props.poll.id),
            ]);

            props.setOpen(false);
            setRejectOpen(false);
        } catch (err: unknown) {}
    };

    const onRejectCancelHandler = (form: FormApi<any, Partial<any>>) => {
        form.reset();
        setRejectOpen(false);
    };

    const rejectForm = (
        <Box>
            <Form
                onSubmit={onSubmitRejectHandler}
                validate={validate}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    invalid,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormTextField
                            name='moderationText'
                            label='Reason for rejecting... '
                            fullWidth
                            required
                            autoFocus
                            multiline
                            minRows={3}
                            maxRows={8}
                        />
                        <FormButton
                            type='button'
                            onClick={() => onRejectCancelHandler(form)}
                        >
                            Cancel
                        </FormButton>
                        <FormButton
                            disabled={submitting || pristine || invalid}
                            type='submit'
                        >
                            Reject
                        </FormButton>
                    </form>
                )}
            />
        </Box>
    );

    return (
        <>
            <Modal
                className='moderate-content-modal'
                open={props.open}
                onClose={closeHandler}
            >
                <Box>
                    <Typography variant='h6'>{props.poll.title}</Typography>
                    {props.poll.description && (
                        <Typography paragraph>
                            {props.poll.description}
                        </Typography>
                    )}
                    <Stack direction='row' spacing={2}>
                        <Container>
                            {props.poll.options[0].image && (
                                <img
                                    className='moderate-image'
                                    alt=''
                                    src={
                                        process.env.REACT_APP_IMAGES_URL +
                                        '/' +
                                        props.poll.options[0].image
                                    }
                                />
                            )}
                            {props.poll.options[0].text && (
                                <Typography align='center' variant='h6'>
                                    {props.poll.options[0].text}
                                </Typography>
                            )}
                        </Container>
                        <Container>
                            {props.poll.options[1].image && (
                                <img
                                    className='moderate-image'
                                    alt=''
                                    src={
                                        process.env.REACT_APP_IMAGES_URL +
                                        '/' +
                                        props.poll.options[1].image
                                    }
                                />
                            )}
                            {props.poll.options[1].text && (
                                <Typography align='center' variant='h6'>
                                    {props.poll.options[1].text}
                                </Typography>
                            )}
                        </Container>
                    </Stack>
                    <Divider />
                    {rejectOpen && rejectForm}
                    {!rejectOpen && (
                        <>
                            <FormButton onClick={onApproveClickHandler}>
                                Approve
                            </FormButton>
                            <FormButton onClick={onRejectClickHandler}>
                                Reject
                            </FormButton>
                        </>
                    )}
                </Box>
            </Modal>
            {/* <PollModerationReject
                open={rejectOpen}
                setOpen={setRejectOpen}
                poll={props.poll}
                polls={props.polls}
                setPolls={props.setPolls}
                setParentOpen={props.setOpen}
            /> */}
        </>
    );
};

export default PollModeration;
