import AWS from 'aws-sdk';
import { AlertObj } from '../../../components/Alerts/Alerts';
import { GET_USER_ERROR_MSG } from '../types';

export const SIGNIN_ALERT_PARAM = 'msg';
export const CHALLENGE_ERROR_MSG = 'challenge';

export enum SignInAlertTypes {
    WrongEmailOrPassword = 'wrong-email-or-password',
    GetUserError = 'get-user-error',
    RegistrationIncomplete = 'registration-incomplete',
}

export class ChallengeException extends Error {
    public data: AWS.CognitoIdentityServiceProvider.InitiateAuthResponse;

    constructor(
        msg: string,
        data: AWS.CognitoIdentityServiceProvider.InitiateAuthResponse,
    ) {
        super(msg);
        this.data = data;
    }
}

export const signInAlerts: { [key: string]: AlertObj } = {
    [SignInAlertTypes.WrongEmailOrPassword]: {
        text: 'Incorrect email or password',
        severity: 'error',
    },
    [SignInAlertTypes.GetUserError]: {
        text: GET_USER_ERROR_MSG,
        severity: 'error',
    },
    [SignInAlertTypes.RegistrationIncomplete]: {
        text: 'You need to complete your registration before signing in here',
        severity: 'error',
    },
};
