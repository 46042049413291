import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import {
    ProcessesContext,
    ProcessesContextType,
} from '../../../contexts/processes';
import { Session, SessionContext } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import AppRouter from '../AppRouter/AppRouter';

const App = (): JSX.Element => {
    const [cookies] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const [session, setSession] = useState<Session>(
        cookies[CookieNames.Session] ?? {},
    );
    const { processes, setProcesses } =
        useContext<ProcessesContextType>(ProcessesContext);
    const [tout, setTout] = useState<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (session.expiresAt && !tout) {
            const timeout = session.expiresAt - Date.now();

            setTout(
                setTimeout(() => {
                    if (session.idToken) {
                        setSession({});
                    }
                }, timeout),
            );
        } else if (!session.expiresAt) {
            setTout(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            <ProcessesContext.Provider value={{ processes, setProcesses }}>
                <AppRouter />
            </ProcessesContext.Provider>
        </SessionContext.Provider>
    );
};

export default App;
