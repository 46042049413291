import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import CommentAPI from '../../../api/CommentAPI';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { Comment, CommentStatus } from '../../../models/Comment';
import CommentModerationReject from './CommentModerationReject/CommentModerationReject';

import './CommentModeration.scss';

interface CommentModerationProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    comment: Comment;
    comments: Comment[];
    setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}

const CommentModeration = (props: CommentModerationProps): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const closeHandler = () => {
        props.setOpen(false);
    };
    const [rejectOpen, setRejectOpen] = useState<boolean>(false);

    const onApproveClickHandler: React.MouseEventHandler<
        HTMLButtonElement
    > = async () => {
        try {
            await CommentAPI.update(
                props.comment.id,
                {
                    status: CommentStatus.Approved,
                },
                session,
            );

            props.setComments([
                ...props.comments.filter(
                    (comm) => comm.id !== props.comment.id,
                ),
            ]);

            props.setOpen(false);
        } catch (err: unknown) {}
    };

    const onRejectClickHandler: React.MouseEventHandler<
        HTMLButtonElement
    > = async () => {
        setRejectOpen(true);
    };

    return (
        <>
            <Modal
                className='moderate-content-modal'
                open={props.open}
                onClose={closeHandler}
            >
                <Box>
                    <Typography>{props.comment.text}</Typography>
                    <Divider />
                    <Button onClick={onApproveClickHandler}>Approve</Button>
                    <Button onClick={onRejectClickHandler}>Reject</Button>
                </Box>
            </Modal>
            <CommentModerationReject
                open={rejectOpen}
                setOpen={setRejectOpen}
                comment={props.comment}
                comments={props.comments}
                setComments={props.setComments}
                setParentOpen={props.setOpen}
            />
        </>
    );
};

export default CommentModeration;
