import AWS from 'aws-sdk';

import { Session } from '../../../contexts/session';
import { ReactCookieType } from '../../../utils/cookies/types';
import AuthController from '../AuthController';
import {
    ChallengeException,
    CHALLENGE_ERROR_MSG,
    SignInAlertTypes,
} from './types';

class SignInController {
    public static login = async (
        username: string,
        password: string,
        setSession: (session: Session) => void,
        cookieArr: ReactCookieType,
    ): Promise<void> => {
        const params: AWS.CognitoIdentityServiceProvider.Types.InitiateAuthRequest =
            {
                AuthFlow: 'USER_PASSWORD_AUTH',
                ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
                AuthParameters: {
                    USERNAME: username,
                    PASSWORD: password,
                },
            };

        const cognitoInitAuthPromise =
            SignInController.getCognitoInitAuthPromiseFunction();

        let data: AWS.CognitoIdentityServiceProvider.InitiateAuthResponse;
        try {
            data = await cognitoInitAuthPromise(params);
        } catch (err: unknown) {
            throw new Error(SignInAlertTypes.WrongEmailOrPassword);
        }

        if (data.AuthenticationResult) {
            await AuthController.validateAndInitSession(
                data.AuthenticationResult,
                setSession,
                cookieArr,
            );
        } else if (data.ChallengeName) {
            throw new ChallengeException(CHALLENGE_ERROR_MSG, data);
        }
    };

    private static getCognitoInitAuthPromiseFunction = (): ((
        params: AWS.CognitoIdentityServiceProvider.Types.InitiateAuthRequest,
    ) => Promise<AWS.CognitoIdentityServiceProvider.InitiateAuthResponse>) => {
        const cognitoidentityserviceprovider =
            AuthController.getCognitoIdSrvProvider();

        return (
            params: AWS.CognitoIdentityServiceProvider.Types.InitiateAuthRequest,
        ): Promise<AWS.CognitoIdentityServiceProvider.InitiateAuthResponse> => {
            return new Promise((resolve, reject): void => {
                cognitoidentityserviceprovider.initiateAuth(
                    params,
                    (
                        err: AWS.AWSError,
                        data: AWS.CognitoIdentityServiceProvider.InitiateAuthResponse,
                    ): void => {
                        if (err) {
                            reject(err);
                        } else if (data) {
                            resolve(data);
                        }

                        reject(
                            new Error(
                                'Unknown cognitoidentityserviceprovider.initiateAuth() Error',
                            ),
                        );
                    },
                );
            });
        };
    };
}

export default SignInController;
