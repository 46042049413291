import { CookieSetOptions } from 'universal-cookie';

import { Session } from '../../contexts/session';

export enum CookieNames {
    Session = 'sess-admin',
    Notifications = 'notif-admin',
}

export type CookiesType = {
    [CookieNames.Session]?: Session;
    [CookieNames.Notifications]?: number;
};

// From useCookies hook (react-cookie library)
export type ReactCookieType = [
    CookiesType,
    (
        name: CookieNames,
        value: any,
        options?: CookieSetOptions | undefined,
    ) => void,
    (name: CookieNames, options?: CookieSetOptions | undefined) => void,
];

export enum CookieNotificationWeight {
    Intro = 1,
}
