import { useContext } from 'react';
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ForumTwoTone as CommentsIcon,
    InventoryTwoTone as PollsIcon,
    LogoutTwoTone as LogoutIcon,
} from '@mui/icons-material';
import { useCookies } from 'react-cookie';

import { RoutePaths } from '../../../core/AppRouter/AppRouter';
import {
    SessionContext,
    SessionContextType,
} from '../../../../contexts/session';
import { CookieNames, CookiesType } from '../../../../utils/cookies/types';

import './LeftMenu.scss';

const LeftMenu = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setSession } = useContext<SessionContextType>(SessionContext);
    const [, , removeCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);

    const absolutePaths = {
        comments: `/${RoutePaths.Comments}`,
        polls: `/${RoutePaths.Polls}`,
    };

    const onLogoutClickHandler: React.MouseEventHandler<
        HTMLButtonElement
    > = () => {
        setSession({});
        removeCookie(CookieNames.Session, {
            path: '/',
            domain: window.location.hostname,
        });
        navigate('/');
    };

    return (
        <Drawer variant='permanent' anchor='left'>
            <Stack className='left-menu' spacing={3}>
                <Tooltip title='Polls' followCursor>
                    <Button
                        className={
                            location.pathname === absolutePaths.polls
                                ? 'selected'
                                : ''
                        }
                        onClick={() => navigate(absolutePaths.polls)}
                    >
                        <PollsIcon />
                    </Button>
                </Tooltip>
                <Tooltip title='Comments' followCursor>
                    <Button
                        className={
                            location.pathname === absolutePaths.comments
                                ? 'selected'
                                : ''
                        }
                        onClick={() => navigate(absolutePaths.comments)}
                    >
                        <CommentsIcon />
                    </Button>
                </Tooltip>
                <Box className='spacer' />
                <Tooltip title='Sign Out' followCursor>
                    <Button onClick={onLogoutClickHandler}>
                        <LogoutIcon />
                    </Button>
                </Tooltip>
            </Stack>
        </Drawer>
    );
};

export default LeftMenu;
