import axios, {
    AxiosInstance,
    AxiosResponse,
    RawAxiosRequestConfig,
    RawAxiosRequestHeaders,
} from 'axios';

import { Session } from '../contexts/session';
import { Comment, CommentData, CommentStatus } from '../models/Comment';
import { APIGetPaginatedParams } from './types';

export const CommentAPIPageSize = 100;

interface GetManyCommentsReturnType {
    count: number;
    data: Comment[];
    lastKey?: {
        [key: string]: any;
    };
}

export interface GetManyCommentsRequestType extends APIGetPaginatedParams {
    status?: CommentStatus | undefined;
}

interface CommentAPIUpdateDataType {
    status?: CommentStatus | undefined;
    moderationText?: string;
}

class CommentAPI {
    private static getCommentAPIAxiosObj = (
        session?: Session,
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_COMMENT_API_BASEURL;

        return axios.create({
            baseURL,
            headers,
        });
    };

    public static update = async (
        commentId: string,
        dataIn: CommentAPIUpdateDataType,
        session: Session,
    ): Promise<CommentData> => {
        const commentAPI = CommentAPI.getCommentAPIAxiosObj(session);

        const { status, data } = await commentAPI.patch<
            CommentData,
            AxiosResponse<CommentData>,
            CommentAPIUpdateDataType
        >(`/${commentId}`, dataIn);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error updating comment');
        }
    };

    public static getMany = async (
        params: GetManyCommentsRequestType,
    ): Promise<GetManyCommentsReturnType> => {
        const commentAPI = CommentAPI.getCommentAPIAxiosObj();

        const limit = params?.scanLimit ?? CommentAPIPageSize.toString();
        const config: RawAxiosRequestConfig<GetManyCommentsRequestType> = {
            params: {
                scanLimit: limit.toString(),
            },
        };

        if (config.params && params?.status) {
            config.params.status = params?.status;
        }

        if (config.params && params?.lastKey) {
            config.params.lastKey = JSON.stringify(params?.lastKey);
        }

        const { status, data } = await commentAPI.get<
            GetManyCommentsReturnType,
            AxiosResponse<GetManyCommentsReturnType>,
            GetManyCommentsRequestType
        >('', config);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving comments');
        }
    };
}

export default CommentAPI;
