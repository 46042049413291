export enum CommentStatus {
    New = 'new',
    Approved = 'approved',
    Rejected = 'rejected',
    Deleted = 'deleted',
}
export interface CommentData {
    pollId: string;
    text: string;
    statusAndCreated?: string;
}

export interface Comment extends CommentData {
    id: string;
    userId: string;
    status: CommentStatus;
    createdAt?: number;
}
