import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import jwt_decode from 'jwt-decode';

import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError,
} from './utils/data-management';
import { Session } from '../contexts/session';
import { apiError } from './types';
import { NewUser, User } from '../models/User';

interface DecodedToken {
    'custom:userId'?: string;
}

class UserAPI {
    private static getUserAPI = (): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json',
        };

        return axios.create({
            baseURL: process.env.REACT_APP_USER_API_BASEURL,
            headers,
        });
    };

    public static create = async (
        userData: NewUser,
    ): Promise<apiError | undefined> => {
        const userAPI = UserAPI.getUserAPI();

        try {
            await userAPI.post('', userData);
        } catch (err: any) {
            let error: apiError;
            const { status, data } = err.response;

            // Validation error
            if (status === 400 && data instanceof Array && data.length) {
                error = apiErrorsToConsolidatedErrorObject(data);
            } else {
                error = generateApiUnexpectedError('creating user');
            }

            return error;
        }
    };

    static get = async (session: Session, userId: string): Promise<User> => {
        const userAPI = UserAPI.getUserAPI();

        const { status, data } = await userAPI.get<User>(`/${userId}`, {
            headers: {
                Authorization: `Bearer ${session.idToken}`,
            },
        });

        if (status === 200 && data && data.id && data.email && data.username) {
            return data;
        } else {
            throw new Error('Error retrieving logged in user');
        }
    };

    static getSelf = async (session: Session): Promise<User> => {
        if (session.idToken) {
            const decodedToken = jwt_decode<DecodedToken>(session.idToken);

            if (decodedToken && decodedToken['custom:userId']) {
                return UserAPI.get(session, decodedToken['custom:userId']);
            }
        }

        throw new Error('Session token missing or invalid');
    };
}

export default UserAPI;
