import { Button } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactNode, useContext, useEffect, useState } from 'react';

import PageContainer from '../../components/layout/PageContainer/PageContainer';
import { Comment, CommentStatus } from '../../models/Comment';
import {
    CommentsController,
    PollCommentsControllerState,
} from './CommentsController';
import { usePrivatePage } from '../../utils/session';
import RenderCellExpand from '../../components/datagrid/RenderCellExpand/RenderCellExpand';
import CommentModeration from './CommentModeration/CommentModeration';
import { SessionContext, SessionContextType } from '../../contexts/session';

import './Comments.scss';

const Comments = (): JSX.Element => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [state, setState] = useState<PollCommentsControllerState>({
        endOfList: false,
    });
    const [modOpen, setModOpen] = useState<boolean>(false);
    const [commentToModerate, setCommentToModerate] = useState<Comment>();
    const { session } = useContext<SessionContextType>(SessionContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(usePrivatePage(), [session]);

    const columns: GridColumns<Comment> = [
        {
            field: 'id',
            headerName: 'Comment ID',
            flex: 0,
            minWidth: 300,
        },
        { field: 'userId', headerName: 'User ID', flex: 0, minWidth: 300 },
        {
            field: 'text',
            headerName: 'Comment Text',
            minWidth: 200,
            flex: 1,
            renderCell: RenderCellExpand,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            renderCell: (
                params: GridRenderCellParams<any, Comment, any>,
            ): ReactNode => {
                const onClickHandler: React.MouseEventHandler<
                    HTMLButtonElement
                > = async () => {
                    setCommentToModerate(params.row);
                    setModOpen(true);
                };

                return <Button onClick={onClickHandler}>Moderate</Button>;
            },
            flex: 0,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    useEffect(() => {
        CommentsController.readMoreComments(
            CommentStatus.New,
            comments,
            setComments,
            state,
            setState,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer className='comments-page-container'>
            <h1>Comments</h1>
            <DataGrid rows={comments} columns={columns} pageSize={25} />
            {commentToModerate !== undefined && (
                <CommentModeration
                    open={modOpen}
                    setOpen={setModOpen}
                    comment={commentToModerate}
                    comments={comments}
                    setComments={setComments}
                />
            )}
        </PageContainer>
    );
};

export default Comments;
