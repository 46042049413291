export enum PollStatus {
    New = 'new',
    Approved = 'approved',
    Rejected = 'rejected',
    Deleted = 'deleted',
}

interface PollOption {
    text: string;
    image?: string;
    votes: number;
}

export interface Poll {
    id: string;
    userId: string;
    title: string;
    options: PollOption[];
    description?: string;
    categoryId: string;
    comments: number;
}
