import AWS from 'aws-sdk';

import UserAPI from '../../api/UserAPI';
import { Session } from '../../contexts/session';
import { CookieNames, ReactCookieType } from '../../utils/cookies/types';
import { SignInAlertTypes } from './SignIn/types';
import { User } from '../../models/User';
import { sessionCookieDuration } from '../../utils/cookies';

class AuthController {
    public static validateAndInitSession = async (
        authResult: AWS.CognitoIdentityServiceProvider.AuthenticationResultType,
        setSession: (session: Session) => void,
        cookieArr: ReactCookieType,
    ): Promise<void> => {
        const [, setCookie] = cookieArr;

        const newSession: Session = {
            idToken: authResult.IdToken,
            accessToken: authResult.AccessToken,
            expiresAt: Date.now() + 60000 * sessionCookieDuration,
        };

        if (authResult.IdToken) {
            try {
                const user: User = await UserAPI.getSelf(newSession);

                newSession.user = user;
                setSession(newSession);

                setCookie(CookieNames.Session, newSession, {
                    path: '/',
                    expires: new Date(
                        Date.now() + 60000 * sessionCookieDuration,
                    ),
                    domain: window.location.hostname,
                    secure: true,
                });
            } catch (_e) {
                setSession({});
                throw new Error(SignInAlertTypes.GetUserError);
            }
        }
    };

    public static getCognitoIdSrvProvider =
        (): AWS.CognitoIdentityServiceProvider => {
            AWS.config.update({ region: process.env.REACT_APP_COGNITO_REGION });
            return new AWS.CognitoIdentityServiceProvider();
        };
}

export default AuthController;
