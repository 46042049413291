import { Button } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactNode, useContext, useEffect, useState } from 'react';

import PageContainer from '../../components/layout/PageContainer/PageContainer';
import { usePrivatePage } from '../../utils/session';
import RenderCellExpand from '../../components/datagrid/RenderCellExpand/RenderCellExpand';
import { Poll, PollStatus } from '../../models/Poll';
import { PollControllerState, PollsController } from './PollsController';
import PollModeration from './PollModeration/PollModeration';
import { SessionContext, SessionContextType } from '../../contexts/session';

import './Polls.scss';

const pageSize = 25;

const Polls = (): JSX.Element => {
    const [polls, setPolls] = useState<Poll[]>([]);
    const [state, setState] = useState<PollControllerState>({
        endOfList: false,
    });
    const [modOpen, setModOpen] = useState<boolean>(false);
    const [pollToModerate, setPollToModerate] = useState<Poll>();
    const { session } = useContext<SessionContextType>(SessionContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(usePrivatePage(), [session]);

    const columns: GridColumns<Poll> = [
        {
            field: 'id',
            headerName: 'Poll ID',
            flex: 0,
            minWidth: 300,
        },
        { field: 'userId', headerName: 'User ID', flex: 0, minWidth: 300 },
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            flex: 1,
            renderCell: RenderCellExpand,
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'actions',
            renderCell: (
                params: GridRenderCellParams<any, Poll, any>,
            ): ReactNode => {
                const onClickHandler: React.MouseEventHandler<
                    HTMLButtonElement
                > = async () => {
                    setPollToModerate(params.row);
                    setModOpen(true);
                };

                return <Button onClick={onClickHandler}>Moderate</Button>;
            },
            flex: 0,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    useEffect(() => {
        if (polls.length < pageSize) {
            PollsController.readMorePolls(
                PollStatus.New,
                polls,
                setPolls,
                state,
                setState,
                session,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polls]);

    return (
        <PageContainer className='polls-page-container'>
            <h1>Polls</h1>
            <DataGrid rows={polls} columns={columns} pageSize={pageSize} />
            {pollToModerate !== undefined && (
                <PollModeration
                    open={modOpen}
                    setOpen={setModOpen}
                    poll={pollToModerate}
                    polls={polls}
                    setPolls={setPolls}
                />
            )}
        </PageContainer>
    );
};

export default Polls;
