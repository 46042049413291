import { useContext, useEffect } from 'react';

import PageContainer from '../../components/layout/PageContainer/PageContainer';
import { SessionContext, SessionContextType } from '../../contexts/session';
import { usePrivatePage } from '../../utils/session';

const Home = (): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(usePrivatePage(), [session]);

    return (
        <PageContainer>
            <h1>SeleOne Admin</h1>
        </PageContainer>
    );
};

export default Home;
