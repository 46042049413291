import { PropsWithChildren } from 'react';
import { Container } from '@mui/material';

import LeftMenu from './LeftMenu/LeftMenu';

import './PageContainer.scss';

interface PageContainerProps {
    className?: string | undefined;
}

const PageContainer = (
    props: PropsWithChildren<PageContainerProps>,
): JSX.Element => {
    const containerClasses =
        'page-container' + (props.className ? ` ${props.className}` : '');

    return (
        <Container className={containerClasses}>
            <LeftMenu />
            {props.children}
        </Container>
    );
};

export default PageContainer;
