import { Dispatch } from 'react';
import PollAPI, { GetManyPollsRequestType } from '../../api/PollAPI';
import { Session } from '../../contexts/session';
import { Poll, PollStatus } from '../../models/Poll';

export interface PollControllerState {
    endOfList: boolean;
    lastKey?: {
        [key: string]: any;
    };
}

export class PollsController {
    public static readMorePolls = async (
        status: PollStatus,
        polls: Poll[],
        setPolls: Dispatch<React.SetStateAction<Poll[]>>,
        state: PollControllerState,
        setState: Dispatch<React.SetStateAction<PollControllerState>>,
        session: Session,
    ): Promise<Boolean> => {
        let newPollsAdded = 0;
        let cumulativePolls: Poll[] = [];

        let newState: PollControllerState = state;

        while (!newState.endOfList && newPollsAdded <= 0) {
            const params: GetManyPollsRequestType = {
                status,
                lastKey: newState.lastKey,
            };

            const result = await PollAPI.getMany(params, session);

            if (result.data) {
                if (result.data.length > 0) {
                    cumulativePolls = [...cumulativePolls, ...result.data];
                    setPolls([...polls, ...cumulativePolls]);
                    newPollsAdded += result.data.length;
                }

                newState = {
                    lastKey: result.lastKey ?? undefined,
                    endOfList: !Boolean(result.lastKey),
                };
            }

            setState(newState);
        }

        return (
            (Boolean(polls) && Boolean(polls.length)) || Boolean(newPollsAdded)
        );
    };
}
