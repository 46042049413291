import './FormTitle.scss';

interface FormTitleProps {
    text: string;
}

const FormTitle = (props: FormTitleProps): JSX.Element => {
    return <h1 className='form-title'>{props.text}</h1>;
};

export default FormTitle;
