import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePaths } from '../components/core/AppRouter/AppRouter';

import { ProcessesContext, ProcessesContextType } from '../contexts/processes';
import { SessionContext, SessionContextType } from '../contexts/session';
import { CookieNames, CookiesType } from './cookies/types';

export const usePrivatePage = (): (() => void) => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [cookies] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const navigate = useNavigate();
    const location = useLocation();
    const { processes, setProcesses } =
        useContext<ProcessesContextType>(ProcessesContext);

    if (
        cookies[CookieNames.Session] === undefined ||
        session.idToken === undefined ||
        session.user === undefined
    ) {
        return () => {
            setProcesses({ ...processes, redirect: location.pathname });
            navigate(`/${RoutePaths.SignIn}`);
        };
    }

    return () => void 0;
};
