import { Box } from '@mui/system';
import React from 'react';

import './FormRowContainer.scss';

interface FormRowContainerProps {
    className?: string;
}

const FormRowContainer = (
    props: React.PropsWithChildren<FormRowContainerProps>,
): JSX.Element => {
    const className = props.className
        ? `form-row-container ${props.className}`
        : 'form-row-container';

    return <Box className={className}>{props.children}</Box>;
};

export default FormRowContainer;
