import { createContext } from 'react';
import { User } from '../models/User';

export interface Session {
    idToken?: string;
    accessToken?: string;

    // Session expirarion in seconds
    expiresAt?: number;

    // Logged in user (if available)
    user?: User;
}

export interface SessionContextType {
    session: Session;
    setSession: (session: Session) => void;
}

export const SessionContext = createContext<SessionContextType>({
    session: {},
    setSession: (_s: Session) => {
        return;
    },
});
