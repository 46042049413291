import { Box, Paper, Popper, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ReactNode, useEffect, useRef, useState } from 'react';

const isOverflown = (element: HTMLDivElement): boolean => {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
};

// interface GridCellExpandProps {
//     width: number;
//     value: any;
// }

const RenderCellExpand = (params: GridRenderCellParams): ReactNode => {
    // const GridCellExpand = (props: GridCellExpandProps): JSX.Element => {
    const width = params.colDef.computedWidth;
    const wrapper = useRef<HTMLDivElement>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef<HTMLDivElement>();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = cellValue.current
            ? isOverflown(cellValue.current)
            : false;
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        const handleKeyDown = (nativeEvent: KeyboardEvent) => {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {params.value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{
                            minHeight: (wrapper.current?.offsetHeight ?? 3) - 3,
                        }}
                    >
                        <Typography
                            variant='body2'
                            style={{ padding: 8, whiteSpace: 'pre-wrap' }}
                        >
                            {params.value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
};

export default RenderCellExpand;
