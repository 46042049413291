import { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import Overlay from '../../../components/layout/Overlay/Overlay';
import PageContainer from '../../../components/layout/PageContainer/PageContainer';
import CardContainer from '../../../components/layout/CardContainer/CardContainer';
import { validateFormValues } from '../../../utils/forms/validation';
import FormTitle from '../../../components/forms/FormTitle/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer/FormRowContainer';
import FormTextField from '../../../components/forms/FormTextField/FormTextField';
import FormButton from '../../../components/forms/FormButton/FormButton';
import SignInController from './SignInController';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import { ChallengeException, signInAlerts, SignInAlertTypes } from './types';
import {
    ProcessesContext,
    ProcessesContextType,
} from '../../../contexts/processes';

import './SignIn.scss';

const validate = validateFormValues(
    yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
    }),
);

const SignIn = (): JSX.Element => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const { session, setSession } =
        useContext<SessionContextType>(SessionContext);
    const cookieArr = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
    ]);
    const { processes } = useContext<ProcessesContextType>(ProcessesContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (session.idToken) {
            navigate(processes.redirect ?? '/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    const onSubmitHandler = async (values: any): Promise<{} | void> => {
        setProcessing(true);

        try {
            await SignInController.login(
                values.email,
                values.password,
                setSession,
                cookieArr,
            );
        } catch (e: unknown) {
            if (e instanceof ChallengeException) {
                setAlerts([
                    signInAlerts[SignInAlertTypes.RegistrationIncomplete],
                ]);
            } else if (e instanceof Error) {
                setAlerts([signInAlerts[e.message as SignInAlertTypes]]);
            }
            // We stop processing if we return an error
            setProcessing(false);
        }
    };

    return (
        <PageContainer>
            <Overlay processing={processing} />
            <Container id='sign-in-page-container'>
                <Alerts alerts={alerts} setAlerts={setAlerts} />
                <CardContainer id='sign-in-form-container'>
                    <Form
                        onSubmit={onSubmitHandler}
                        validate={validate}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            invalid,
                        }) => (
                            <form id='sign-in-form' onSubmit={handleSubmit}>
                                <FormTitle text='Sign In' />
                                <FormRowContainer>
                                    <FormTextField
                                        name='email'
                                        label='Email'
                                        fullWidth
                                        required
                                        autoFocus
                                        autoComplete='email'
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormTextField
                                        name='password'
                                        label='Password'
                                        fullWidth
                                        required
                                        autoComplete='current-password'
                                        type='password'
                                    />
                                </FormRowContainer>
                                <FormRowContainer>
                                    <FormButton
                                        disabled={
                                            submitting || pristine || invalid
                                        }
                                        type='submit'
                                    >
                                        Submit
                                    </FormButton>
                                </FormRowContainer>
                            </form>
                        )}
                    />
                </CardContainer>
            </Container>
        </PageContainer>
    );
};

export default SignIn;
