import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { envVarOk } from './utils/sanity';
import App from './components/core/App/App';

import './index.scss';

if (envVarOk()) {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement,
    );
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}
