import { Dispatch } from 'react';

import CommentAPI, { GetManyCommentsRequestType } from '../../api/CommentAPI';
import { Comment } from '../../models/Comment';
import { CommentStatus } from '../../models/Comment';

export interface PollCommentsControllerState {
    endOfList: boolean;
    lastKey?: {
        [key: string]: any;
    };
}

export class CommentsController {
    public static readMoreComments = async (
        status: CommentStatus,
        comments: Comment[],
        setComments: Dispatch<React.SetStateAction<Comment[]>>,
        state: PollCommentsControllerState,
        setState: Dispatch<React.SetStateAction<PollCommentsControllerState>>,
    ): Promise<Boolean> => {
        let newCommentsAdded = 0;
        let cumulativeComments: Comment[] = [];

        let newState: PollCommentsControllerState = state;

        while (!newState.endOfList && newCommentsAdded <= 0) {
            const params: GetManyCommentsRequestType = {
                status,
                lastKey: newState.lastKey,
            };

            const result = await CommentAPI.getMany(params);

            if (result.data) {
                if (result.data.length > 0) {
                    if (result.data.length > 0) {
                        cumulativeComments = [
                            ...cumulativeComments,
                            ...result.data,
                        ];
                        setComments([...comments, ...cumulativeComments]);
                        newCommentsAdded += result.data.length;
                    }
                }

                newState = {
                    lastKey: result.lastKey ?? undefined,
                    endOfList: !Boolean(result.lastKey),
                };
            }

            setState(newState);
        }

        return (
            (Boolean(comments) && Boolean(comments.length)) ||
            Boolean(newCommentsAdded)
        );
    };
}
